<template>
  <!-- eslint-disable -->
  <svg width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-240.000000, -4080.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(160.000000, 80.000000)">
              <path d="M15,1 L14.7750617,1.00413847 C11.5655749,1.12242824 9,3.76160306 9,7 L9,9 L7,9 C6.44771525,9 6,9.44771525 6,10 L6,14 L6.00672773,14.1166211 C6.06449284,14.6139598 6.48716416,15 7,15 L9,15 L9,22 C9,22.5522847 9.44771525,23 10,23 L14,23 L14.1166211,22.9932723 C14.6139598,22.9355072 15,22.5128358 15,22 L15,15 L17,15 C17.4588676,15 17.8588507,14.6877026 17.9701425,14.2425356 L18.9701425,10.2425356 L18.991488,10.1314823 C19.0711701,9.54157633 18.6123023,9 18,9 L15,9 L15,7 L18,7 C18.5522847,7 19,6.55228475 19,6 L19,2 C19,1.44771525 18.5522847,1 18,1 L15,1 Z M17,3 L17,5 L15,5 C13.8954305,5 13,5.8954305 13,7 L13,10 L13.0067277,10.1166211 C13.0644928,10.6139598 13.4871642,11 14,11 L16.718,11 L16.218,13 L14,13 L13.8833789,13.0067277 C13.3860402,13.0644928 13,13.4871642 13,14 L13,21 L11,21 L11,14 L10.9932723,13.8833789 C10.9355072,13.3860402 10.5128358,13 10,13 L8,13 L8,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,7 C11,4.790861 12.790861,3 15,3 L17,3 Z" id="icon"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'FacebookSvg',
};
</script>
