<template>
  <div class="layout landing-layout">
    <landing-header />

    <div class="layout-content">
      <router-view />
    </div>

    <landing-footer />
  </div>
</template>

<script>
import LandingHeader from '@/components/landing/LandingHeader.vue';
import LandingFooter from '@/components/landing/LandingFooter.vue';

export default {
  components: {
    LandingHeader,
    LandingFooter,
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/views/landing/landing";
</style>
