<template>
  <div class="landing-header">
    <div class="container">
      <div class="landing-header__logo">
        <router-link to="/">
          <logo-svg />
        </router-link>
      </div>
      <div class="landing-header__links">
        <router-link
          class="action-label"
          :to="{ name: 'SignIn', params: { type: 'employer' } }"
        >
          Employer Sign In
        </router-link>
        <router-link
          class="action-label"
          :to="{ name: 'SignIn', params: { type: 'agency' } }"
        >
          Agency Sign In
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from '@/components/svg/LogoSvg.vue';

export default {
  name: 'LandingHeader',
  components: {
    LogoSvg,
  },
};
</script>
