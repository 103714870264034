<template>
  <div class="landing-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-3 col-lg-4 col-md-5 col-12 order-1 order-md-0">
          <div class="landing-footer__links">
            <a
              class="link-regular link-regular--light-hover"
              href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/terms_of_use.pdf"
              target="_blank"
            >
              Terms of Service
            </a>
            <a
              class="link-regular link-regular--light-hover"
              href="https://the-vault-biz-prod.s3.eu-central-1.amazonaws.com/docs/privacy_policy.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-2 col-12 order-2 order-md-1">
          <div class="landing-footer__copyright">©2021 by TheVault</div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-5 col-12 order-0 order-md-2 landing-footer-wrapper">
          <a href="tel:818-585-7217" class="landing-footer__phone">
            <phone-svg />
            <span>818-585-7217</span>
          </a>

          <div class="landing-footer__socials d-none">
            <a href="#" target="_blank" rel="nofollow noopener" class="link-social">
              <facebook-svg />
            </a>

            <a href="#" target="_blank" rel="nofollow noopener" class="link-social">
              <twitter-svg />
            </a>

            <a href="#" target="_blank" rel="nofollow noopener" class="link-social">
              <linkedin-svg />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneSvg from '@/components/svg/PhoneSvg.vue';
import FacebookSvg from '@/components/svg/FacebookSvg.vue';
import TwitterSvg from '@/components/svg/TwitterSvg.vue';
import LinkedinSvg from '@/components/svg/LinkedinSvg.vue';

export default {
  name: 'LandingFooter',
  components: {
    PhoneSvg,
    FacebookSvg,
    TwitterSvg,
    LinkedinSvg,
  },
};
</script>
